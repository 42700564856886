<template>
  <div class="StudentScore">
    <h3 class="mark"></h3>
    <el-table
      :data="tableData"
      style="width: 100%"
      :row-class-name="tableRowClassName"
    >
      <el-table-column prop="date" label="日期"> </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="course" label="课程名"> </el-table-column>
      <el-table-column prop="score" label="成绩"> </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "StudentScore",
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.score < 70) {
        return "warning-row";
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="less" scope>
.StudentScore {
  padding: 8px 15px;
}
.el-table .warning-row {
  background: oldlace;
}
.el-table .success-row {
  background: #f0f9eb;
}
.el-table__row td:last-of-type .cell {
  color: #606266;
}
</style>
